
import { ButtonContent } from "../global/boton/ButtonContent";
// import { GiRibbonMedal } from "react-icons/gi";
// import { AiOutlineTeam } from "react-icons/ai";
// import { RiPlantFill } from "react-icons/ri";

function HeroSection({ bgImg, bgImg1, bgImg2, bgImg3, title, texts, name }) {

  const BgImages = [
    bgImg,
    bgImg1,
    bgImg2,
    bgImg3,
  ]
  return (
    <>
      <div className="pic-wrapper  w-full h-screen overflow-x-hidden" >
        {
          BgImages.map((item, index) => {
            return (
              <figure
                key={index}
                className={`pic-${index + 1}`}
                style={{ background: `url("${item}")` }}
              ></figure>
            )
          })
        }
        <div className="z-[4] relative w-[90%] mx-auto h-full flex md:flex-row flex-col justify-center items-center md:pb-[80px] md:pt-[28%] pt-[380px] pb-[40px]">

          <div className="flex md:flex-row flex-col md:w-[95%] mx-auto bottom-[30px] md:bottom-[100px] lg:bottom-[120px] md:text-start text-center text-white">
            <div className="w-full text-center md:text-start p-4">
              <p className="font-semibold text-[20px] md:text-[1.5em] lg:text-[1.5em]">Welcome To:</p>
              <h1 className="text-[30px] md:text-[3em] lg:text-[3.2em]">{name}</h1>
              <h2 className="text-[20px] md:text-[1.5em] lg:text-[1.5em]">{title}</h2>
              <p className="px-5 md:px-[10%]">{texts}</p>
              <ButtonContent btnStyle="three" />
            </div>

          </div>
          <img
            src="https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Marcas%20de%20Autos%2Fplatinum-removebg-preview.png?alt=media&token=41fef826-6a8c-4d49-80d8-af35f7a22942"
            alt="car"
            className="w-[200px] md:w-[300px] lg:w-[700px] h-auto absolute -right-20 -bottom-6"
            data-aos="fade-left"
          />

        </div>

      </div>
      {/* <div className=" md:w-[70%] w-[100%] bg-2 text-white md:-mt-14 -mt-20 relative md:py-14 py-2 homi">
        <ul className="flex md:flex-row flex-col justify-around ml-5 md:ml-0">
          <h5 className="flex items-center" data-aos="zoom-in">
            <RiPlantFill
              fontSize={70}
              className="text-white mr-2"
            />
            BEST SERVICE</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1000">
            <AiOutlineTeam
              fontSize={70}
              className="text-white mr-2"
            />
            PROFESSIONAL TEAM</h5>
          <h5 className="flex items-center" data-aos="zoom-in" data-aos-duration="1500">
            <GiRibbonMedal
              fontSize={70}
              className="text-white"
            />BEST QUALITY</h5>
        </ul>
      </div> */}
    </>
  );
}

export default HeroSection;
